import React, { Component } from 'react';
import { graphql } from 'gatsby';

import { Headline } from '../components';
import PriceTable from '../components/PriceTable';
import Layout from '../components/Layout';

class Preise extends Component {
  render() {
    const { frontmatter } = this.props.data.preise.childMarkdownRemark;

    return (
      <Layout location={this.props.location}>
        <Headline>{frontmatter.title}</Headline>
        <PriceTable prices={frontmatter} />
      </Layout>
    );
  }
}

export default Preise;

export const query = graphql`
  query PreiseQuery {
    preise: file(relativePath: { eq: "preise.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          kategorie {
            name
            gruppen {
              name
              wert
              positionen {
                name
                preis
              }
            }
          }
        }
      }
    }
  }
`;
