import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: ${props => props.theme.colors.textPrimary};
  margin-left: -1vw;
`;

const Kategorie = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  padding: 2vw 1vw 0 1vw;
  min-width: 50%;
  // &:first-child {
  //   padding: 2vw 1vw 0 0;
  // }
  // &:last-child {
  //   padding: 2vw 0 0 1vw;
  // }
`;

const KategorieName = styled.h3`
  margin-bottom: 1vw;
`;

const Gruppe = styled.div``;

const GruppeSolo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
`;

const GruppenName = styled.h4`
  margin-top: 1vw;
  font-weight: 500;
`;

const PositionNameSolo = styled.span`
  font-weight: 500;
`;

const PositionName = styled.span`
  font-weight: lighter;
`;

const Preis = styled.span`
  padding: 0 0.5vw;
  font-weight: lighter;
  white-space: nowrap;
`;

const PriceTable = props => (
  <Container>
    {props.prices.kategorie.map((kategorie, i) => (
      <Kategorie key={i}>
        <KategorieName>{kategorie.name}</KategorieName>
        {kategorie.gruppen.map((gruppe, i) => (
          <ul key={i}>
            <li>
              {gruppe.positionen ? (
                <Gruppe>
                  <GruppenName>{gruppe.name}</GruppenName>
                  {gruppe.positionen.map((position, i) => (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PositionName>{position.name}</PositionName>
                      <Preis>{position.preis}</Preis>
                    </div>
                  ))}
                </Gruppe>
              ) : gruppe.wert ? (
                <Gruppe>
                  <GruppeSolo>
                    <PositionNameSolo>{gruppe.name}</PositionNameSolo>
                    <Preis>{gruppe.wert}</Preis>
                  </GruppeSolo>
                </Gruppe>
              ) : null}
            </li>
          </ul>
        ))}
      </Kategorie>
    ))}
  </Container>
);

export default PriceTable;
